import React, { useEffect, useState } from "react";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Toolbar } from "primereact/toolbar";
import jsPDF from "jspdf";
import "jspdf-autotable";
import QRCode from "qrcode";
import moment from "moment";
import "moment/locale/es"; // Import Spanish locale
import { Dropdown } from "primereact/dropdown";
import { useGlobalContext } from "../Config";
import { format } from "date-fns";

// Helper Functions
const formatRut = (rut) => {
  if (!rut) return "";
  const cleanRut = rut.replace(/[^0-9kK]/g, "");
  const body = cleanRut.slice(0, -1);
  const dv = cleanRut.slice(-1).toUpperCase();
  const formattedBody = body.replace(/\B(?=(\d{3})+(?!\d))/g, "");
  return `${formattedBody}-${dv}`;
};

const isValidRUT = (rut) => {
  if (!rut || typeof rut !== "string") return false;
  const cleanRut = rut.replace(/[^0-9kK]/g, "");
  if (cleanRut.length < 2) return false;
  const body = cleanRut.slice(0, -1);
  const dv = cleanRut.slice(-1).toUpperCase();
  let sum = 0;
  let multiplier = 2;
  for (let i = body.length - 1; i >= 0; i--) {
    sum += multiplier * parseInt(body[i], 10);
    multiplier = multiplier === 7 ? 2 : multiplier + 1;
  }
  const expectedDV = 11 - (sum % 11);
  return (
    (expectedDV === 10
      ? "K"
      : expectedDV === 11
      ? "0"
      : expectedDV.toString()) === dv
  );
};

const formatDateToDDMMYYYY = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
};

const ConsultaCertificados = () => {

  const queryParameters = new URLSearchParams(window.location.search)
  const codigo = queryParameters.get("codigo");

  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();

  const [certificados, setCertificados] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleSelectionChange = (e) => {
    // Filter out selections where certificado is true
    const newSelection = e.value.filter((row) => !row.certificado);
    setSelectedTickets(newSelection);
  };

  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
    rut: "",
    estado: 1,
    razonSocial: "",
  });

  const [recipient, setRecipient] = useState({
    rut: "",
    razonSocial: "",
  });

  const [errors, setErrors] = useState({
    filters: { rut: "" },
    recipient: { rut: "", razonSocial: "" },
  });

  const generateImprovedCertificadoPDF = async (idCertificado, estado, fechaSolicitud) => {
    window.open("https://proveedores.aza.cl/certificado/" + idCertificado);
  };

  const generateImprovedCertificadoPDF0 = async (idCertificado, estado, fechaSolicitud) => {

    const pdf = new jsPDF("p", "mm", "a4");

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const marginLeft = 30;
    const marginTop = 15;
    const footerHeight = 70; // Fixed footer height
    const contentHeight = pageHeight - marginTop - footerHeight; // Height available for content
    let currentY = marginTop; // Start content position

    let tableData = [];
    let background = await loadBackgroundImage();

    try {
      setLoading(true);
      const response = await fetch(
        `${baseUrl}getDataPDFByIdCertificado?idCertificado=${idCertificado}`,
        {
          method: "GET",
          credentials: "include",
          headers: headerText,
        }
      );

      if (!response.ok) {
        throw new Error("Error fetching tickets");
      }
      const data = await response.json();
      if (data && data.certificados && data.certificados.length > 0) {
        const getValueOrDefault = (value, defaultValue = "No info") => {
          return (typeof value === "string" || typeof value === "number") &&
            value !== null
            ? value
            : defaultValue;
        };

        tableData = data.certificados.map((item) => [
          getValueOrDefault(item.oc),
          getValueOrDefault(item.numero_ticket),
          getValueOrDefault(item.nombre_transportista),
          getValueOrDefault(item.rut_transportista, "Falta RUT"),
          getValueOrDefault(item.patente),
          getValueOrDefault(item.guia),
          getValueOrDefault(item.peso),
          getValueOrDefault(item.unidad),
          item.fecha ? formatDateToDDMMYYYY(item.fecha) : "No info",
          getValueOrDefault(item.planta),
          getValueOrDefault(item.proveedor_razon_social),
          getValueOrDefault(item.proveedor_rut),
          getValueOrDefault(item.proveedor_codigo_sap),
          getValueOrDefault(item.rut_destinatario),
          getValueOrDefault(item.razon_social_destinatario),
        ]);
      } 
      else {
        alert("Este certificado no tiene registros");
        return;
      }
    } 
    catch (error) {
      alert(`Error al solicitar registros del certificado: ${error.message}`);
      return;
    } 
    finally {
      setLoading(false);
    }
    
    async function loadBackgroundImage() {

      const logoUrlRemote = "https://proveedores.aza.cl/react/logoFondoAza.png"; // Remote logo URL
      const logoUrlLocal = "/logoFondoAza.png"; // Local logo URL

      async function fetchImage(url) {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      }

      try {
        return await fetchImage(logoUrlRemote);
      } catch (error) {
        console.error(
          "Failed to load remote logo, falling back to local logo:",
          error
        );
        try {
          return await fetchImage(logoUrlLocal);
        } 
        catch (localError) {
          console.error("Failed to load local logo as well:", localError);
          throw new Error("Failed to load any logo image."); // Re-throw or handle accordingly
        }
      }
    }

    // Function to add background image to a page
    function addBackgroundImage(startY, height) {
      if (estado === "APROBADO") {
        const imageWidth = pageWidth - marginLeft - 30;
        pdf.addImage(background, "PNG", marginLeft, startY, imageWidth, height);
      } 
      else {
        const watermarkText =
          estado === "SOLICITADO" ? "SOLICITADO" : "RECHAZADO";

        const textX = pageWidth / 2;
        const textY = pageHeight / 2;

        pdf.saveGraphicsState();

        if (watermarkText === "SOLICITADO") {
          pdf.setTextColor(169, 169, 169); // Gray
        } 
        else {
          pdf.setTextColor(255, 0, 0);
        }
        pdf.setFontSize(60);
        pdf.setFont("helvetica", "bold");

        // Add estado watermark diagonally
        pdf.text(watermarkText, textX + 20, textY + 10, {
          angle: 45, // Rotate diagonally
          align: "center",
        });

        pdf.restoreGraphicsState();
      }
    }

    const addPageFooter = async (pdf) => {

      let ofuscado = 0;
      if (idCertificado <= 10000000) {
        ofuscado = 10000000 + idCertificado;
      }
      else {
        ofuscado = idCertificado;
      }
      const qrCodeData = "https://proveedores.aza.cl/react/#/certificado/" + ofuscado.toString(32);
      const qrCodeImage = await QRCode.toDataURL(qrCodeData, { width: 100 });
      const qrCodeSize = 30; // Size of the QR code
      const qrCodeMargin = 10; // Margin around the QR code

      // Define the position for the footer
      const qrCodeX = (pdf.internal.pageSize.width - qrCodeSize) / 2 - 50;
      const qrCodeY =
        pdf.internal.pageSize.height - footerHeight + qrCodeMargin;

      // Check if there is space for the footer
      const currentY = pdf.lastAutoTable?.finalY || 0; // Assuming you use autoTable or similar
      if (currentY + footerHeight > pdf.internal.pageSize.height) {
        pdf.addPage(); // Add a new page if footer would overlap content
      }

      // Add QR code to the footer
      pdf.addImage(
        qrCodeImage,
        "PNG",
        qrCodeX,
        qrCodeY,
        qrCodeSize,
        qrCodeSize
      );

      pdf.text("Validador de", qrCodeX + 15, qrCodeY - 3, {align: "center"});
      pdf.text("Autenticidad", qrCodeX + 15, qrCodeY + 1, {align: "center"});

      const pdfWidth = pdf.internal.pageSize.getWidth();

      // Ajusta la posición en el eje Y, basada en la posición del QR
      const textYBase = qrCodeY + 10;

      // Configurar la fuente y tamaño
      pdf.setFontSize(10);
      pdf.setFont("helvetica", "bold");

      // Texto "Emitido por David Rincón" centrado
      pdf.text("Emitido por David Rincón", pdfWidth / 2, textYBase, {
        align: "center",
      });

      pdf.setFont("helvetica", "normal");

      // Texto "contactoweb@aza.cl" centrado
      pdf.text("contactoweb@aza.cl", pdfWidth / 2, textYBase + 5, {
        align: "center",
      });

      // Texto "ACEROS AZA S.A." centrado
      pdf.text("ACEROS AZA S.A.", pdfWidth / 2, textYBase + 10, {
        align: "center",
      });

      // Add footer lines
      const footerLines = [
        "ACERO AZA S.A.",
        "La Unión 3070, Renca, Santiago Chile • Código Postal 7464522 • Telefono: 56 26418683",
        "Planta Colina Panamericana Norte Km. 18 1/2, Colina, Santiago Chile • Fono: 56 26779500",
        "www.aza.cl",
      ];

      pdf.setFontSize(8);
      footerLines.forEach((line, index) => {
        const footerY = qrCodeY + 35 + index * 5;
        const textWidth =
          (pdf.getStringUnitWidth(line) * pdf.internal.getFontSize()) /
          pdf.internal.scaleFactor;
        const textX = (pdf.internal.pageSize.width - textWidth) / 2;
        pdf.text(line, textX, footerY);
      });
    };

    const addTitleAndCode = (pdf) => {
      pdf.setFontSize(9.5);

      pdf.setFont("arial", "normal");
      pdf.text(`Código: ${idCertificado}`, marginLeft, currentY);
      currentY += 5;
      pdf.setFont("arial", "bold");
      const title = "CERTIFICADO";
      const titleWidth =
        (pdf.getStringUnitWidth(title) * pdf.internal.getFontSize()) /
        pdf.internal.scaleFactor;
      const titleX = (pageWidth - titleWidth) / 2;
      pdf.text(title, titleX, currentY);
      currentY += 1;

      pdf.line(titleX, currentY, titleX + titleWidth, currentY); // Line below title
      currentY += 10;
      pdf.setFont("arial", "normal");
      // Now add the background image starting from the current Y
      const bgStartY = currentY; // Start Y position for the background
      const bgHeight = contentHeight - bgStartY + marginTop; // Calculate the height to cover
      addBackgroundImage(bgStartY - 15, bgHeight);
    };

    const logoUrlRemote = "https://proveedores.aza.cl/react/aza.png"; // Remote logo URL
    const logoUrlLocal = "/aza.png"; // Local logo URL
    const logoWidth = 40;
    const logoHeight = 20;
    const logoX = pageWidth - logoWidth - marginLeft;

    try {
      pdf.addImage(
        logoUrlRemote,
        "PNG",
        logoX,
        marginTop,
        logoWidth,
        logoHeight
      );
    } catch (error) {
      console.error(
        "Failed to load remote logo, falling back to local logo:",
        error
      );
      try {
        pdf.addImage(
          logoUrlLocal,
          "PNG",
          logoX,
          marginTop,
          logoWidth,
          logoHeight
        );
      } catch (localError) {
        console.error("Failed to load local logo as well:", localError);
      }
    }
    currentY += logoHeight + 5;
    addTitleAndCode(pdf);

    pdf.setFont("arial", "bold");
    pdf.setFontSize(10);
    const indent = 10;

    pdf.text(
      "ACEROS AZA S.A., Rut 92.176.000-0",
      marginLeft + indent,
      currentY,
      {
        maxWidth: pageWidth - 2 * marginLeft,
      }
    );

    let boldTextWidth = pdf.getTextWidth("ACEROS AZA S.A., Rut 92.176.000-0");

    pdf.setFont("arial", "normal");

    pdf.text(
      " empresa siderúrgica, declara que en su proceso de producción",
      marginLeft + indent + boldTextWidth,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - boldTextWidth }
    );

    currentY += 4;

    pdf.text(
      "utiliza como materia prima básicamente chatarra ferrosa que es procesada y seleccionada para ingresar posteriormente al proceso de aceración de nuestra planta ubicada en Panamericana Norte N°18.968, comuna de Colina en la ciudad de Santiago.",
      marginLeft,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );
    currentY += 16;

    pdf.setFont("arial", "bold");

    pdf.text("ACEROS AZA S.A", marginLeft + indent, currentY, {
      maxWidth: pageWidth - 2 * marginLeft,
    });

    let boldText2Width = pdf.getTextWidth("ACEROS AZA S.A ");

    pdf.setFont("arial", "normal");

    pdf.text(
      " cuenta con las autorizaciones extendidas por las SEREMI DE SALUD",
      marginLeft + indent + boldText2Width,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - boldText2Width }
    );
    currentY += 4;

    pdf.text(
      "correspondientes para el almacenamiento y procesamiento de chatarra en nuestra planta en la Región Metropolitana y centros de reciclaje ubicados en las ciudades de Antofagasta, Concepción y Temuco cuyas resoluciones son:",
      marginLeft,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );
    currentY += 14;

    pdf.setFont("arial", "normal");

    const texts = [
      "• Región Metropolitana. Resolución N°5862, del 14 de marzo de 2000.",
      "• Antofagasta. Resolución Nº3352, del 02 de octubre de 2008.",
      "• Temuco. Resolución Nº67, del 20 de junio de 2008.",
      "• Talcahuano. Resolución Exenta N°768, del 03 de agosto de 2016.",
    ];

    texts.forEach((text) => {
      pdf.text(text, marginLeft + indent, currentY); // Imprime el texto centrado
      currentY += 6; // Espaciado entre líneas
    });

    currentY += 4; // Espaciado adicional después de la lista

    const text1 = "El Modelo de Gestión en ";
    const boldText = "ACEROS AZA S.A. ";
    const text2 = " se basa en el Premio Nacional a la Calidad y las normas";

    // Obtener ancho de los textos para alinearlos correctamente
    const text1Width = pdf.getTextWidth(text1);
    const boldText3Width = pdf.getTextWidth(boldText);

    // Posición inicial
    let x = marginLeft;
    let y = currentY;

    // Primera parte (normal)
    pdf.setFont("arial", "normal");
    pdf.text(text1, x + indent, y);

    // Segunda parte (negrita)
    pdf.setFont("arial", "bold");
    pdf.text(boldText, x + text1Width + indent, y);

    // Tercera parte (normal)
    pdf.setFont("arial", "normal");
    pdf.text(text2, x + text1Width + boldText3Width + indent, y, {
      maxWidth: pageWidth - 2 * marginLeft - (text1Width + boldText3Width),
      align: "justify",
    });

    currentY += 4;

    pdf.text(
      "ISO 9002, ISO 14001 Y OHSAS 18001. Este Modelo y las normas proveen el marco para el mejoramiento continuo y el aseguramiento de todos nuestros sistemas productivos.",
      marginLeft,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );
    currentY += 12;

    pdf.setFont("arial", "bold");
    pdf.text(
      `${tableData[0][10]} RUT: ${tableData[0][11]} `,
      marginLeft + indent,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );

    let boldText4Width = pdf.getTextWidth(
      `${tableData[0][10]} RUT: ${tableData[0][11]}`
    );
    pdf.setFont("arial", "normal");

    pdf.text(
      ", es proveedor de nuestra empresa ",
      marginLeft + indent + boldText4Width,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - boldText4Width }
    );
    currentY += 4;

    pdf.text(
      `ACEROS AZA S.A. a través del código interno `,
      marginLeft,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );

    const text2Width = pdf.getTextWidth("del código interno ");

    pdf.setFont("arial", "bold");
    pdf.text(
      `N.º ${tableData[0][12]}, `,
      marginLeft + text2Width,
      currentY + 4,
      {
        maxWidth: pageWidth - 2 * marginLeft - text2Width,
        align: "justify",
      }
    );

    const text5Width = pdf.getTextWidth(`N.º ${tableData[0][12]},`);

    pdf.setFont("arial", "normal");
    pdf.text(
      " del sistema informático SAP.",
      marginLeft + text2Width + text5Width,
      currentY + 4,
      {
        maxWidth: pageWidth - 2 * marginLeft - text5Width,
        align: "justify",
      }
    );

    currentY += 12;

    pdf.setFont("arial", "normal");

    pdf.text(
      "A petición del proveedor y para ser presentado ante",
      marginLeft + indent,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );

    const text3Width = pdf.getTextWidth(
      "A petición del proveedor y para ser presentado ante "
    );

    pdf.setFont("arial", "bold");
    pdf.text(
      `${tableData[0][14]}. RUT:`,
      marginLeft + indent + text3Width,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - text3Width, align: "justify" }
    );
    currentY += 4;

    pdf.setFont("arial", "bold");

    pdf.text(`${tableData[0][13]}, `, marginLeft, currentY, {
      maxWidth: pageWidth - 2 * marginLeft,
      align: "justify",
    });

    const text4Width = pdf.getTextWidth(`${tableData[0][13]}, `);

    pdf.setFont("arial", "normal");
    pdf.text(
      "Certificamos la siguiente entrega de chatarra ferrosa en planta:",
      marginLeft + text4Width,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft - text4Width, align: "justify" }
    );

    const text6Width = pdf.getTextWidth(
      "Certificamos la siguiente entrega de chatarra ferrosa en planta: "
    );

    pdf.setFont("arial", "bold");

    pdf.text(
      `${tableData[0][9]}.`,
      marginLeft + text4Width + text6Width,
      currentY,
      {
        maxWidth: pageWidth - 2 * marginLeft - text4Width - text6Width,
        align: "justify",
      }
    );

    currentY += 8;

    pdf.setFont("arial", "bold");

    pdf.text(
      "Este documento se emite para efectos y fines estrictamente comerciales.",
      marginLeft + indent,
      currentY,
      { maxWidth: pageWidth - 2 * marginLeft, align: "justify" }
    );

    currentY += 10;

    pdf.autoTable({
      startY: currentY,
      head: [
        [
          "OC",
          "TICKET",
          "NOMBRE TRANSPORTISTA",
          "RUT TRANSPORTISTA",
          "PATENTE",
          "#GUÍA",
          "PESO",
          "UNIDAD",
          "FECHA",
        ],
      ],
      body: [...tableData],
      margin: { left: 20 },
      theme: "grid",
      styles: { fontSize: 8 },
      headStyles: { fontSize: 8 },
    });

    if (fechaSolicitud) {
      const formattedDate = moment(fechaSolicitud, "YYYY-MM-DD HH:mm:ss").format("D [de] MMMM [del] YYYY");
      pdf.setFont("arial", "normal");
      pdf.setFontSize(10);
      pdf.text(`Santiago, ${formattedDate}`, pageWidth - marginLeft - 10, currentY + 60, { align: "right" });
    }

    await addPageFooter(pdf);

    const pdfData = pdf.output("bloburl");
    window.open(pdfData);
  };

  const handleRutChange = (value, fieldName, section) => {
    // Allow only numbers and 'k' or 'K'
    const sanitizedValue = value.replace(/[^0-9kK]+/g, "");
    const formattedRut = formatRut(sanitizedValue);
    const isValid = isValidRUT(formattedRut);
    const newErrors = {
      ...errors[section],
      rut: isValid ? "" : "RUT incorrecto. Revise el formato.",
    };

    if (section === "filters") {
      setFilters({ ...filters, [fieldName]: formattedRut });
      setErrors({ ...errors, filters: newErrors });
    } else if (section === "recipient") {
      setRecipient({ ...recipient, [fieldName]: formattedRut });
      setErrors({ ...errors, recipient: newErrors });
    }
  };

  const handleSearch = async () => {
    const { fromDate, toDate, rut, estado, razonSocial } = filters;

    if (!razonSocial && !fromDate && !toDate && !rut && !estado) {
      alert("Debe ingresar al menos un filtro.");
      return;
    }

    if (rut && !isValidRUT(rut)) {
      setErrors((prev) => ({
        ...prev,
        filters: {
          ...prev.filters,
          rut: "RUT incorrecto. Revise el formato.",
        },
      }));
      alert("RUT incorrecto. Revise el formato.");
      return;
    }
    setErrors((prev) => ({
      ...prev,
      filters: { ...prev.filters, rut: "" },
    }));
    setCertificados([]);
    try {
      setLoading(true);
      // Format filters for API request
      const params = new URLSearchParams({
        desde: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "",
        hasta: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
        estado,
        rutProveedor: rut || "",
        razonSocialProveedor: razonSocial || "",
      });
      console.log("params", params);
      // API Call
      const response = await fetch(`${baseUrl}getCertificados?${params}`, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      });

      if (!response.ok) {
        throw new Error("Error fetching tickets");
      }

      const data = await response.json();
      setCertificados(data.certificados);
    } catch (error) {
      alert("Error fetching tickets. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const estados = [
    { label: "Solicitado", value: 1 },
    { label: "Aprobado", value: 2 },
    { label: "Rechazado", value: 3 },
  ];
  const handleCertificadoAction = async (
    idCertificado,
    idUsuario,
    aprobarO
  ) => {
    try {
      setLoading(true);
      const endpoint = aprobarO ? "aprobarCertificado" : "rechazarCertificado";
      const actionText = aprobarO ? "aprobado" : "rechazado";
      const actionText2 = aprobarO ? "aprobar" : "rechazar";
      const confirmation = window.confirm(
        `¿Está seguro que desea ${actionText2} el certificado?`
      );
      if (!confirmation) {
        return;
      }
      const response = await fetch(`${baseUrl}${endpoint}`, {
        method: "POST",
        credentials: "include",
        headers: {
          ...headerText,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          idCertificado: idCertificado,
          idUsuario: idUsuario,
        }),
      });

      if (!response.ok) {
        throw new Error(`Error al ${actionText2} el certificado`);
      }

      const data = await response.json();
      if (data.respuesta) {
        alert(data.mensaje || `Certificado ${actionText} correctamente.`);
        handleSearch(); // Refresh the data after the action
      } else {
        alert(data.mensaje || `No se pudo ${actionText2} el certificado.`);
      }
    } catch (error) {
      alert(
        `Error al ${
          aprobarO ? "aprobar" : "rechazar"
        } el certificado. Intente nuevamente.`
      );
    } finally {
      setLoading(false);
    }
  };

  const renderCertificateButton = (rowData) => {
    if (rowData.estado === "SOLICITADO") {
      return (
        <div
          style={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}
        >
          {sessionStorage.getItem("permisos").includes(11) && 
            <Button
              label="Aprobar"
              icon="pi pi-check"
              className="p-button-sm p-button-success"
              style={{
                transition: "transform 0.3s ease",
                width: "120px",
              }}
              onClick={() =>
                handleCertificadoAction(
                  rowData.id,
                  sessionStorage.getItem("idUsuario"),
                  true
                )
              }
              disabled={loading}
            />
          }
          {sessionStorage.getItem("permisos").includes(11) && 
            <Button
              label="Rechazar"
              icon="pi pi-times"
              className="p-button-sm p-button-danger"
              style={{
                transition: "transform 0.3s ease",
                width: "120px",
              }}
              onClick={() =>
                handleCertificadoAction(
                  rowData.id,
                  sessionStorage.getItem("idUsuario"),
                  false
                )
              }
              disabled={loading}
            />
          }
          <Button
            label="Ver Certificado"
            icon="pi pi-eye"
            className="p-button-sm p-button-info"
            style={{
              transition: "transform 0.3s ease",
              width: "120px",
            }}
            disabled={loading}
            onClick={() =>
              generateImprovedCertificadoPDF(rowData.id, rowData.estado, rowData.fechaSolicitud)
            }
          />
        </div>
      );
    } else {
      return (
        <div
          style={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}
        >
          <Button
            label="Ver Certificado"
            icon="pi pi-eye"
            className="p-button-sm p-button-info"
            style={{
              transition: "transform 0.3s ease",
              width: "120px",
            }}
            disabled={loading}
            onClick={() =>
              generateImprovedCertificadoPDF(rowData.id, rowData.estado)
            }
          />
        </div>
      );
    }
  };
  const handleTicketNumberChange = (value) => {
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    setFilters({ ...filters, ticketNumber: sanitizedValue });
  };
  const renderEstado = (rowData) => {
    const estadoStyles = {
      APROBADO: { color: "green", fontWeight: "bold" },
      RECHAZADO: { color: "red", fontWeight: "bold" },
      default: { color: "gray", fontWeight: "normal" },
    };

    const estadoStyle = estadoStyles[rowData.estado] || estadoStyles.default;

    return <span style={estadoStyle}>{rowData.estado}</span>;
  };
  return (
    <div className="p-4">
      {/* Header Toolbar */}
      <Toolbar
        className="mb-4"
        left={() => (
          <h3 style={{ transform: "translateY(0)", transition: "all 0.8s" }}>
            Consulta Certificados
          </h3>
        )}
      />

      {/* Filters */}
      <Card className="mb-4 shadow-3">
        <div className="p-fluid grid">
          <div className="col-12 md:col-6 lg:col-3">
            <label htmlFor="fromDate">Fecha Desde</label>
            <Calendar
              id="fromDate"
              value={filters.fromDate}
              onChange={(e) => setFilters({ ...filters, fromDate: e.value })}
              showIcon
              dateFormat="dd/mm/yy"
              disabled={loading}
            />
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <label htmlFor="toDate">Fecha Hasta</label>
            <Calendar
              id="toDate"
              value={filters.toDate}
              onChange={(e) => setFilters({ ...filters, toDate: e.value })}
              showIcon
              dateFormat="dd/mm/yy"
              disabled={loading}
            />
          </div>

          <div className="col-12 md:col-6 lg:col-3">
            <label htmlFor="ticketNumber">Estado</label>

            <Dropdown
              id="estadoDropdown"
              value={filters.estado}
              onChange={(e) => setFilters({ ...filters, estado: e.value })}
              options={estados}
              placeholder="Selecciona un estado"
              disabled={loading}
              className="w-full"
            />
          </div>
          <div className="col-12 md:col-6 lg:col-3">
            <label htmlFor="rut">RUT Proveedor</label>
            <InputText
              id="rut"
              value={filters.rut}
              onChange={(e) =>
                handleRutChange(e.target.value, "rut", "filters")
              }
              placeholder="Ej: 12345678-9"
              disabled={loading}
              className={errors.filters.rut ? "p-invalid" : ""}
            />

            {errors.filters.rut && (
              <small className="p-error">{errors.filters.rut}</small>
            )}

            {errors.filters.rut && (
              <small className="p-error">{errors.filters.rut}</small>
            )}
          </div>
          <div className="col-12">
            <label htmlFor="razonSocial">Razón Social Proveedor</label>
            <InputText
              id="razonSocial"
              value={filters.razonSocial}
              disabled={loading}
              onChange={(e) =>
                setFilters({ ...filters, razonSocial: e.target.value })
              }
            />
          </div>
          <div className="col-12 md:col-6 lg:col-8">
            <Button
              label="Buscar Certificados"
              icon="pi pi-search"
              className="p-button w-full mt-3"
              onClick={handleSearch}
              style={{ backgroundColor: "rgb(0, 122, 217)" }}
              disabled={loading}
            />
          </div>
          <div className="col-12 md:col-6 lg:col-4">
            <Button
              label="Limpiar Filtros"
              icon="pi pi-eraser"
              className="p-button-success w-full mt-3"
              onClick={() => {
                setFilters({
                  ...filters,
                  fromDate: null,
                  toDate: null,
                  rut: "",
                  estado: 1,
                  razonSocial: "",
                });
                setErrors({ ...errors, filters: { rut: "" } });
              }}
              disabled={loading}
            />
          </div>
        </div>
      </Card>

      <Divider />

      {/* Tickets Table */}
      <Card
        title={`Total Certificados Encontrados: ${certificados.length}`}
        className="mb-4 shadow-3"
      >
        {certificados.length > 0 ? (
          <>
            {/* Ticket Count */}

            {/* Tickets Table */}
            <DataTable
              value={certificados}
              dataKey="ticket"
              className="p-datatable-gridlines"
              rows={20}
              paginator
              emptyMessage="No se encontraron certificados"
              loading={loading}
            >
              <Column field="proveedor" header="Proveedor" sortable />
              <Column
                field="fechaSolicitud"
                header="Fecha"
                sortable
                body={(rowData) =>
                  rowData.fechaSolicitud
                    ? format(new Date(rowData.fechaSolicitud), "dd/MM/yyyy")
                    : "-"
                }
              />
              <Column field="solicitante" header="Solicitante" sortable />
              <Column field="comprador" header="Comprador" sortable />
              <Column
                field="estado"
                header="Estado"
                sortable
                body={renderEstado}
              />
              <Column body={renderCertificateButton} />
            </DataTable>
          </>
        ) : (
          // No Tickets Found Message
          <div className="text-center py-4">
            <p className="text-lg">No se encontraron certificados.</p>
          </div>
        )}
      </Card>

      <Divider />
    </div>
  );
};

export default ConsultaCertificados;
