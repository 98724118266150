import React, { useEffect, useState, useCallback } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { useGlobalContext } from "../Config";
import { Card } from "primereact/card";
import { Toolbar } from "primereact/toolbar";

import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx'

const Detalle = (props) => {
  
  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();

  const [desde, setDesde] = useState(new Date());
  const [hasta, setHasta] = useState(new Date());
  const [planta, setPlanta] = useState([]);
  const [plantas, setPlantas] = useState([]);
  const [selectedPlantas, setSelectedPlantas] = useState([]);
  const [guia, setGuia] = useState([]);
  const [ticket, setTicket] = useState([]);
  const [factura, setFactura] = useState([]);
  const [proveedor, setProveedor] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [patente, setPatente] = useState([]);
  const [facturados, setFacturados] = useState("TODOS");
  const [pagados, setPagados] = useState("TODOS");
  const [tickets, setTickets] = useState([]);

  const setLoading = (value) => {
    props.setLoading(value);
  };

  const options = [
    { label: "Todos", value: "TODOS" },
    { label: "Si", value: "SI" },
    { label: "No", value: "NO" },
  ];

  const getPlantas = async () => {
    const endpoint = baseUrl + "plantas/list";
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }
      setPlantas(data.plantas);
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const getProveedores = async () => {
    const endpoint = baseUrl + "proveedores/simple";
    try {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: headerText,
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to fetch data");
      }
      setProveedores(data.proveedores);
    } catch (error) {
      console.error("Error", error.message);
    }
  };

  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedEmpresas, setSelectedEmpresas] = useState("");

  useEffect(() => {
    getPlantas();
    getProveedores();
  }, []);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    switch (name) {
      case "guia":
        setGuia(val);
        break;
      case "ticket":
        setTicket(val);
        break;
      case "factura":
        setFactura(val);
        break;
      case "proveedor":
        setProveedor(val);
        break;
      case "patente":
        setPatente(val);
        break;
      case "facturados":
        setFacturados(val);
        break;
      case "pagados":
        setPagados(val);
        break;
      case "planta":
        setPlanta(val);
        break;
    }
  };

  const exportar = () => {
    const worksheet = XLSX.utils.json_to_sheet(tickets);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tickets");
    // XLSX.utils.sheet_add_aoa(workbook, ["Acreedor", "Proveedor", "Ticket", "Fecha", "Guia", "Descripcion", "Material", "Peso Neto", "Peso Tara", "Peso Bruto", "Impureza", "Peso Pagado", "Unidad", "Monto Facturado", "Placa Vehiculo", "Doc. de Compra", "Contrato", "Facturado", "Nº Factura", "Fecha de Pago", "Vencimiento", "Estado de Pago"], { origin: "A1" });

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {type: "application/octet-stream"});
    saveAs(blob, "tickets.xlsx");
  };  
  
  const handleSubmit = useCallback((e) => {
    setLoading(true);

    e.preventDefault();

    var endpoint = baseUrl + "reportes/detalle";
    endpoint +=
      "?desde=" +
      desde.getFullYear() +
      "-" +
      (desde.getMonth() + 1) +
      "-" +
      desde.getDate();
    endpoint +=
      "&hasta=" +
      hasta.getFullYear() +
      "-" +
      (hasta.getMonth() + 1) +
      "-" +
      hasta.getDate();
    if (guia.length > 0) {
      endpoint += "&guia=" + guia;
    }
    if (ticket.length > 0) {
      endpoint += "&ticket=" + ticket;
    }
    if (factura.length > 0) {
      endpoint += "&factura=" + factura;
    }
    if (proveedor != "") {
      endpoint += "&proveedor=" + proveedor;
    }
    if (patente.length > 0) {
      endpoint += "&patente=" + patente;
    }

    switch (facturados) {
      case "SI":
        endpoint += "&facturados=true";
        break;
      case "NO":
        endpoint += "&facturados=true";
        break;
    }
    switch (pagados) {
      case "SI":
        endpoint += "&pagados=true";
        break;
      case "NO":
        endpoint += "&pagados=false";
        break;
    }
    if (selectedPlantas.length > 0) {
      endpoint += "&plantas=";
      for (var key in selectedPlantas) {
        endpoint += selectedPlantas[key].id + ",";
      }
      endpoint = endpoint.substring(0, endpoint.length - 1);
    }
    
    (async () => {
      const data = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
        headers: new Headers(),
      }).then((res) => res.json());
      setTickets(data.detalles);
      setLoading(false);
    })();
  });

  const formatCurrency = (value) => {
    if (value != undefined) {
      return value.toLocaleString("es-CL", { style: "currency", currency: "CLP" })
    }
  }

  const montoTemplate = (rowData) => {
    return formatCurrency(rowData.monto);
  };

  return (
    <>
      <Toolbar
        className="mb-4"
        left={() => (
          <h3 style={{ transform: "translateY(0)", transition: "all 0.8s" }}>
            Consulta de Tickets Detalle{" "}
          </h3>
        )}
      />
      <Card className="col-12 md:col-12">
        <div>
          <form onSubmit={handleSubmit}>
            <div className="p-fluid formgrid grid gap-3">
              <div className="col-12 grid gap-3">
                <div className="field col-12 md:col-3">
                  <label htmlFor="desde">Desde</label>
                  <Calendar
                    value={desde}
                    onChange={(e) => setDesde(e.value)}
                    maxDate={new Date()}
                    dateFormat="dd/mm/yy"
                    disabled={props.loading}
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="hasta">Hasta</label>
                  <Calendar
                    value={hasta}
                    onChange={(e) => setHasta(e.value)}
                    maxDate={new Date()}
                    dateFormat="dd/mm/yy"
                    disabled={props.loading}
                  />
                </div>

                {/* Number Filters */}
                <div className="field col-12 md:col-3">
                  <label htmlFor="guia">Nº Guía</label>
                  <InputText
                    value={guia}
                    onChange={(e) => onInputChange(e, "guia")}
                    disabled={props.loading}
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="ticket">Nº Ticket</label>
                  <InputText
                    value={ticket}
                    onChange={(e) => onInputChange(e, "ticket")}
                    disabled={props.loading}
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="factura">Nº Factura</label>
                  <InputText
                    value={factura}
                    onChange={(e) => onInputChange(e, "factura")}
                    disabled={props.loading}
                  />
                </div>

                {props.proveedor == 0 && (
                <div className="field col-12 md:col-3">
                    <label htmlFor="proveedor">Proveedor</label>
                    <Dropdown 
                      value={proveedor} 
                      onChange={(e) => onInputChange(e, "proveedor")} 
                      options={proveedores} 
                      optionValue="id" 
                      optionLabel="razonSocial" 
                      placeholder="Seleccione" 
                      className="w-full" 
                      disabled={props.loading}
                      virtualScrollerOptions={{ itemSize: 38 }}
                      filter
                    />
                  </div>
                )}

                <div className="field col-12 md:col-3">
                  <label htmlFor="patente">Patente</label>
                  <InputText
                    value={patente}
                    onChange={(e) => onInputChange(e, "patente")}
                    disabled={props.loading}
                  />
                </div>

                <div className="field col-12 md:col-3">
                  <label htmlFor="facturados">Facturados</label>
                  <SelectButton
                    value={facturados}
                    onChange={(e) => onInputChange(e, "facturados")}
                    disabled={props.loading}
                    options={options}
                  />
                </div>
                <div className="field col-12 md:col-3">
                  <label htmlFor="pagados">Pagados</label>
                  <SelectButton
                    value={pagados}
                    onChange={(e) => onInputChange(e, "pagados")}
                    disabled={props.loading}
                    options={options}
                  />
                </div>

                <div className="field col-12 md:col-4">
                  <label htmlFor="planta">Planta</label>
                  <MultiSelect
                    value={selectedPlantas}
                    onChange={(e) => setSelectedPlantas(e.value)}
                    disabled={props.loading}
                    options={plantas}
                    optionLabel="nombre"
                    placeholder="Seleccione Plantas"
                    className="w-full"
                  />
                </div>
              </div>

              <div style={{width: "100%", marginBottom: "12px"}}>

                <div style={{float: "left", marginLeft: "8px"}}>
                  <Button
                    type="submit"
                    className="p-button-sm p-button-info"
                    label="Aplicar"
                    icon="pi pi-search"
                    disabled={props.loading}
                    style={{
                      backgroundColor: "#007ad9",
                      transition: "transform 0.3s ease",
                      width: "150px",
                    }}
                  />
                </div>

                <div style={{float: "right", marginRight: "8px"}}>
                  {tickets.length > 0 && 
                    <Button
                      type="button"
                      icon="pi pi-upload"
                      className="p-button-sm p-button-info"
                      label="Exportar"
                      disabled={props.loading}
                      style={{
                        backgroundColor: "#007ad9",
                        transition: "transform 0.3s ease",
                        width: "150px",
                        float: "right"
                      }}
                      onClick={exportar}
                    />   
                  }
                </div>               

              </div>

            </div>
          </form>
          <DataTable
            value={tickets}
            tableStyle={{ minWidth: "50rem" }}
            showGridlines
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 15, 20, 25, 50]}
            emptyMessage="No se encontraron tickets"
            loading={props.loading}
          >
            <Column field="id" header="#"></Column>
            <Column field="cuenta" header="Acreedor"></Column>
            <Column
              field="razonSocial"
              header="Proveedor"
              style={{ minWidth: "320px" }}
            ></Column>
            <Column field="numeroTicket" header="Ticket"></Column>
            <Column field="fechaCreacion" header="Fecha"></Column>
            <Column field="numeroGuia" header="Guia"></Column>
            <Column
              field="descripcion"
              header="Descripcion"
              style={{ minWidth: "320px" }}
            ></Column>
            <Column field="codigo" header="Material"></Column>
            <Column field="pesoNeto" header="Peso Neto"></Column>
            <Column field="pesoTara" header="Peso Tara"></Column>
            <Column field="pesoBruto" header="Peso Bruto"></Column>
            <Column field="impureza" header="Impureza"></Column>
            <Column field="pesoPagado" header="Peso Pagado"></Column>
            <Column field="unidad" header="Unidad"></Column>
            <Column body={montoTemplate} field="monto" header="Monto Facturado"></Column>
            <Column field="placaVehiculo" header="Placa Vehículo"></Column>
            <Column field="documentoCompra" header="Doc. de Compra"></Column>
            <Column field="contrato" header="Contrato"></Column>
            <Column field="facturado" header="Facturado"></Column>
            <Column field="numeroFactura" header="Nº Factura"></Column>
            <Column field="fechaPago" header="Fecha de Pago"></Column>
            <Column field="vencimiento" header="Vencimiento"></Column>
            <Column field="estadoPago" header="Estado de Pago"></Column>
          </DataTable>
        </div>
      </Card>
    </>
  );
};

export default Detalle;
